import { useState, useEffect } from "react";
import { enGBIntl, ProConfigProvider } from "@ant-design/pro-components";
import loadable from "@loadable/component";
import * as Sentry from "@sentry/react";
import { ConfigProvider, App as AntdApp } from "antd";
import enGB from "antd/locale/en_GB";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter, Route, Switch, useLocation } from "react-router-dom";

import "./App.css";
import "./typography.scss";
import "./resources/css/font_icon.css";
import "./resources/css/theme/header.css";
import "./resources/css/theme/sidebar.css";
import "./resources/css/theme/message.css";
import "./resources/css/theme/overrides.css";
import "./resources/css/theme/containers.css";
import "./resources/css/theme/form.css";
import "./resources/css/theme/cards.css";
import "./resources/css/theme/modal.css";
import "./resources/css/theme/pagination.css";
import "./resources/css/theme/loader.css";
import "./resources/css/theme/elements.css";
import "./resources/css/theme/table.css";
import "./resources/css/theme/buttons.css";

import "./resources/css/spoof.css";
import "./resources/css/compare.css";
import "./resources/css/block_filter.css";
import "./resources/css/block_datepicker.css";
import "./resources/css/block_badge.css";
import "./resources/css/block_search.css";
import "./resources/css/block_review.css";
import "./resources/css/joblist_table.css";
import "./resources/css/update_photo.css";
import "./resources/css/header.css";
import "./resources/css/marketing_website.css";
import "./resources/css/login_and_create_password.css";
import "./resources/css/signup.css";
import "./resources/css/developer_tools.css";
import "./resources/css/dashboard.css";
import "./resources/css/billing_dashboard.css";
import "./resources/css/smile_search_and_filters.css"; // job and live results table
import "./resources/css/results.css";
import "./resources/css/action_list.css";
import "./resources/css/settings.css";
import "./resources/css/enrolled_user.css";
import "./resources/css/review.css";
import "./resources/css/id_card_review.css";
import "./resources/css/web_app.css";
import "./resources/css/web_links.css";
import "./resources/css/job_result.css";
import "./resources/css/metrics.css";
import "./resources/css/id_status.css";
import "./resources/css/charts.css";
import "./resources/css/kyc_form.css";
import "./resources/css/kyc_reviews.css";
import "./resources/css/loader.css";
import "./resources/css/support.css";
import "./resources/css/filter-modal.css";
import "./resources/css/dlocal-modal.css";
import "./resources/css/not_found.css";

import AccessLogs from "components/admin/AccessLogs";
import Reviews from "components/reusable/reviews";
import GlobalStyles from "components/ui/styles";
import AuthAppView from "./AuthAppView";
import AnalyticsWrapper from "./components/admin/analytics_wrapper";

import JobsShow from "./components/admin/show/JobsShow";
import ApplicationError from "./components/application_error/application_error";
import AccountSettings from "./components/partner/account_settings";
import FundWallet from "./components/partner/billing/FundWallet";
import BillingHistory from "./components/partner/billing/history";
import PartnerBillingDashboard from "./components/partner/BillingDashboard";
import PartnerDashboard from "./components/partner/dashboard";
import GetStarted from "./components/partner/developer_tools";
import List from "./components/partner/enterprise_dash/list_view/list";
import JobResult from "./components/partner/job_result";
import KYC from "./components/partner/KYC";
import SupportTickets from "./components/partner/support/support_tickets";
import WebApp from "./components/partner/web_app";
import ApiKeyList from "./components/reusable/ApiKey/ApiKeyList";
import Callback from "./components/reusable/docs/callback";
import SDK from "./components/reusable/docs/sdk";
import IDStatus from "./components/reusable/id_status";
import IDHistory from "./components/reusable/IdHistory";
import KYCForm from "./components/reusable/kyc_form";
import Team from "./components/reusable/team";
import AddWebLinkIds from "./components/reusable/web_links/AddWebLinkIds";
import LinkSettings from "./components/reusable/web_links/LinkSettings";
import ManageSmileLinks from "./components/reusable/web_links/ManageSmileLinks";
import ShareLink from "./components/reusable/web_links/share_link";
import SmileLinks from "./components/reusable/web_links/SmileLinks";
import ReviewerSplash from "./components/reviewers/reviewers_splash";
import ForgotPassword from "./components/unauthenticated/forgot_password";
import Login2FAPage from "./components/unauthenticated/Login2FA";
import ResetPassword from "./components/unauthenticated/reset_password";
import SetUpTFAPage from "./components/unauthenticated/set_up_tfa";
import SignUp from "./components/unauthenticated/SignUp";

import {
  SlackAuth,
  SlackConnect,
} from "./components/unauthenticated/slack-auth";
import CompletedReviewsContainer from "./containers/reusable/completed_reviews_container";
import SettingsContainer from "./containers/reusable/settings_container";
import { SmileLinksFormProvider } from "./contexts/SmileLinksFormContext";
import LoginPage from "./pages/login/login";
import NINV2 from "./pages/nin-v2";
import NotFound from "./pages/NotFound";
import SignUpPage from "./pages/signup";
import theme from "./theme";
import {
  AuthRoute,
  decode,
  ProtectedRoute,
  userHasPermission,
} from "./util/route_util";

// Admin only routes are below. These are loaded asynchronously to reduce the initial bundle size.

const CompaniesContainer = loadable(
  () => import("./containers/admin/companies_container"),
);
const CompareReviewsContainer = loadable(
  () => import("./containers/admin/reviews/compare_reviews_container"),
);
const DashboardFormContainer = loadable(
  () => import("./containers/admin/enterprise_dash/dashboard_form_container"),
);
const EditPartnerContainer = loadable(
  () => import("./containers/admin/partners/edit_partner_container"),
);
const KYCReviews = loadable(() => import("./components/admin/kyc_reviews"));
const Permissions = loadable(() => import("./components/admin/permissions"));
const QueueHealth = loadable(() => import("./components/admin/queue_health"));
const ReviewerPermissions = loadable(
  () => import("./components/admin/reviewer_permissions"),
);
const PricingPlan = loadable(() => import("./pages/pricing-plan"));
const SpoofReviewsContainer = loadable(
  () => import("./containers/admin/reviews/spoof_reviews_container"),
);
const ReviewWrapper = loadable(
  () => import("./components/admin/review_wrapper"),
);

function usePageViews() {
  const location = useLocation();
  const user = localStorage.token ? decode(localStorage.token) : undefined;

  const hideForUser = user?.type !== "partner";
  const hiddenLocations = ["/", "/login/tfa", "/forgot_password"];

  const hideIntercom =
    hiddenLocations.includes(location.pathname) || hideForUser;

  useEffect(() => {
    if (window.analytics) {
      window.analytics.page(
        location.pathname,
        {},
        {
          Intercom: {
            hideDefaultLauncher: hideIntercom,
          },
        },
      );
      window.intercomSettings = {
        custom_launcher_selector: "#open-intercom",
      };
    }
  }, [location]);
}

function App(props) {
  const [timer, setTimer] = useState(null);
  const ldClient = useLDClient();

  usePageViews();

  useEffect(() => {
    const user = localStorage.token ? decode(localStorage.token) : undefined;
    const preOTPUser = localStorage.preOTPUser
      ? JSON.parse(localStorage.preOTPUser)
      : undefined;
    if (!user && !preOTPUser) {
      localStorage.removeItem("adminPartnerId");
      localStorage.removeItem("token");
      localStorage.removeItem("preOTPUser");
      return;
    }

    props.receiveToken({
      auth_token: localStorage.token,
      pre_otp_user: preOTPUser,
    });

    if (user) {
      Sentry.setUser({ id: user.partner_id, email: user.email });
      ldClient?.identify({ key: user.partner_id, email: user.email });
    }

    if (user && user.type === "admin") {
      props.loadPendingReviewAndKYCCount();
      const timer = setInterval(
        () => props.loadPendingReviewAndKYCCount(),
        30000,
      );
      setTimer(timer);
      props.fetchPartners();
    }

    return () => {
      if (timer) clearInterval(timer);
    };
  }, []);

  return (
    <Sentry.ErrorBoundary fallback={<ApplicationError />}>
      <Switch>
        {/* Only match one of these routes */}
        <AuthRoute path="/signup" component={SignUpPage} exact />
        <AuthRoute path="/signup/:invitation_id" component={SignUp} />
        <AuthRoute
          path="/"
          component={LoginPage}
          exact
          pageMeta={{
            title: "Smile ID Portal - Login",
            description: "Smile ID Portal - Login",
            robots: "index, follow",
          }}
        />
        <AuthRoute
          path="/login"
          component={LoginPage}
          exact
          pageMeta={{
            title: "Smile ID Portal - Login",
            description: "Smile ID Portal - Login",
            robots: "index, follow",
          }}
        />
        <AuthRoute path="/login/tfa" component={Login2FAPage} exact />
        <AuthRoute
          path="/set-up-two-factor-authentication"
          component={SetUpTFAPage}
          exact
          pageMeta={{
            title: "Smile ID Portal - Login",
            description: "Smile ID Portal - Login",
            robots: "index, follow",
          }}
        />
        <AuthRoute
          path="/forgot_password"
          component={ForgotPassword}
          exact
          pageMeta={{
            title: "Smile ID Portal - Forgot Password",
            description: "Forgot your password? No worries, we got you!",
            robots: "index, follow",
          }}
        />
        <AuthRoute
          path="/reset_password/:token"
          component={ResetPassword}
          exact
        />
        <Route path="/slack/installed" component={SlackConnect} exact />
        <Route path="/slack/auth" component={SlackAuth} exact />
        <AuthAppView>
          <ProtectedRoute
            viewAs="admin"
            path="/admin/companies/new"
            component={EditPartnerContainer}
          />
          <ProtectedRoute
            viewAs="admin"
            path="/admin/companies/:id/edit"
            component={EditPartnerContainer}
          />
          <ProtectedRoute
            viewAs="admin"
            path="/admin/companies/:partnerID/pricing_plan"
            component={PricingPlan}
          />
          <ProtectedRoute
            enabledEnvToggle
            viewAs="admin"
            path="/admin/companies"
            component={CompaniesContainer}
            pageMeta={{
              title: "Companies",
              description: "Companies",
            }}
          />
          <ProtectedRoute
            path="/admin/:partnerId/job_results/:environment/:id"
            component={JobResult}
          />
          <ProtectedRoute
            path="/admin/:partnerId/kyc/:environment/:userId"
            component={KYC}
          />
          {userHasPermission(["partner full_read"]) && (
            <ProtectedRoute
              viewAs="admin"
              enabledEnvToggle
              path="/admin/access_logs"
              component={AccessLogs}
              pageMeta={{
                title: "Access Logs",
                description: "Access Logs",
              }}
            />
          )}
          <ProtectedRoute
            path="/admin/job/:environment/:id"
            component={JobsShow}
            exact
          />
          <ProtectedRoute
            viewAs="admin"
            enabledEnvToggle
            path="/admin/analytics"
            component={AnalyticsWrapper}
            pageMeta={{
              title: "Analytics",
              description: "Analytics",
            }}
          />
          <ProtectedRoute
            enabledEnvToggle
            viewAs="partner"
            path="/admin/partner_dashboards/:partnerId/job_list"
            component={List}
          />
          <ProtectedRoute
            enabledEnvToggle
            viewAs="partner"
            path="/admin/partner_dashboards/:partnerId/user_list"
            component={List}
          />
          <ProtectedRoute
            enabledEnvToggle
            viewAs="admin"
            path="/admin/partner_dashboards"
            component={DashboardFormContainer}
            pageMeta={{
              title: "View Partner Dashboards",
              description: "View Partner Dashboards",
            }}
          />
          <ProtectedRoute
            viewAs="admin"
            path="/admin/permissions"
            component={Permissions}
            pageMeta={{
              title: "Permissions",
              description: "Permissions",
            }}
          />
          <ProtectedRoute
            viewAs="admin"
            path="/admin/reviewer_permissions"
            component={ReviewerPermissions}
            pageMeta={{
              title: "Reviewer Permissions",
              description: "Reviewer Permissions",
            }}
          />
          <ProtectedRoute
            viewAs="admin"
            enabledEnvToggle
            path="/admin/compare_reviews"
            component={CompareReviewsContainer}
            pageMeta={{
              title: "Compare Reviews",
              description: "Compare Reviews",
            }}
          />
          <ProtectedRoute
            viewAs="admin"
            enabledEnvToggle
            path="/admin/spoof_reviews"
            component={SpoofReviewsContainer}
            pageMeta={{
              title: "Spoof Reviews",
              description: "Spoof Reviews",
            }}
          />
          <ProtectedRoute
            viewAs="admin"
            enabledEnvToggle
            path="/admin/id_card_reviews"
            component={SpoofReviewsContainer}
            pageMeta={{
              title: "ID Card Reviews",
              description: "ID Card Reviews",
            }}
          />
          <ProtectedRoute
            viewAs="admin"
            enabledEnvToggle
            path="/admin/docv_reviews"
            component={SpoofReviewsContainer}
            pageMeta={{
              title: "DocV Reviews",
              description: "DocV Reviews",
            }}
          />
          <ProtectedRoute
            viewAs="admin"
            path="/admin/queue_health"
            component={QueueHealth}
            pageMeta={{
              title: "Queue Health",
              description: "Queue Health",
            }}
          />
          <ProtectedRoute
            viewAs="admin"
            path="/admin/kyc_reviews"
            component={KYCReviews}
            pageMeta={{
              title: "Review KYC",
              description: "Review KYC",
            }}
          />
          <ProtectedRoute
            viewAs="admin"
            enabledEnvToggle
            path="/admin/web_app"
            component={WebApp}
            exact
            pageMeta={{
              title: "SmartCheck™",
              description: "SmartCheck™",
            }}
          />
          <ProtectedRoute path="/admin/id_status" component={IDStatus} exact />
          <ProtectedRoute
            path="/admin/id_status/:country/:id_type/:provider?"
            component={IDHistory}
            exact
          />
          <ProtectedRoute
            viewAs="admin"
            path="/admin/billing/history/:partner_id"
            component={BillingHistory}
            exact
          />
          <ProtectedRoute
            viewAs="admin"
            enabledEnvToggle
            path="/admin/reviews"
            component={Reviews}
            exact
            pageMeta={{
              title: "Open Reviews",
              description: "Open Reviews",
            }}
          />
          <ProtectedRoute
            viewAs="admin"
            enabledEnvToggle
            path="/admin/reviews/completed"
            component={CompletedReviewsContainer}
            exact
          />
          <ProtectedRoute
            viewAs="admin"
            path="/admin/reviews/:id"
            component={ReviewWrapper}
            exact
          />
          <ProtectedRoute
            viewAs="admin"
            enabledEnvToggle
            path="/admin/:environment/smile_links"
            component={SmileLinks}
            pageMeta={{
              title: "Smile Links",
              description: "Smile Links",
            }}
          />
          <SmileLinksFormProvider>
            <ProtectedRoute
              viewAs="admin"
              path="/admin/:environment/smile_links/new"
              component={AddWebLinkIds}
            />
            <ProtectedRoute
              viewAs="admin"
              path="/admin/:environment/smile_links/settings"
              component={LinkSettings}
            />
          </SmileLinksFormProvider>
          <ProtectedRoute
            enabledEnvToggle
            viewAs="admin"
            path="/admin/:environment/smile_links/manage"
            component={ManageSmileLinks}
          />
          <ProtectedRoute
            viewAs="partner"
            enabledEnvToggle
            path="/partner/:environment/smile_links"
            component={SmileLinks}
          />

          <SmileLinksFormProvider>
            <ProtectedRoute
              viewAs="partner"
              path="/partner/:environment/smile_links/new"
              component={AddWebLinkIds}
            />
            <ProtectedRoute
              viewAs="partner"
              path="/partner/:environment/smile_links/settings"
              component={LinkSettings}
            />
          </SmileLinksFormProvider>
          <ProtectedRoute
            enabledEnvToggle
            viewAs="partner"
            path="/partner/:environment/smile_links/manage"
            component={ManageSmileLinks}
          />
          <ProtectedRoute path="/web-links/:id" component={ShareLink} />
          <ProtectedRoute path="/sdk" component={SDK} exact />
          <ProtectedRoute
            path="/settings"
            component={SettingsContainer}
            exact
          />
          <ProtectedRoute path="/settings/team" component={Team} exact />
          <ProtectedRoute
            enabledEnvToggle
            path="/api-key"
            component={ApiKeyList}
            exact
          />
          <ProtectedRoute
            enabledEnvToggle
            path="/callback-url"
            component={Callback}
            exact
          />
          <ProtectedRoute
            enabledEnvToggle
            viewAs="partner"
            path="/partner/job_list"
            component={List}
            exact
          />
          <ProtectedRoute
            enabledEnvToggle
            viewAs="partner"
            path="/partner/user_list"
            component={List}
            exact
          />
          <ProtectedRoute
            path="/partner/job_results/:environment/:id"
            viewAs="partner"
            component={JobResult}
          />
          <ProtectedRoute
            viewAs="partner"
            path="/partner/kyc/:environment/:userId"
            component={KYC}
          />
          <ProtectedRoute
            viewAs="admin"
            path="/admin/kyc_reviews/:partnerId"
            component={KYCForm}
          />
          <ProtectedRoute
            viewAs="partner"
            path="/partner/nin_v2"
            component={NINV2}
          />
          <ProtectedRoute
            path="/partner/developer_tools"
            viewAs="partner"
            component={GetStarted}
            exact
          />
          <ProtectedRoute
            enabledEnvToggle
            path={["/partner/dashboard", "/partner"]}
            viewAs="partner"
            component={PartnerDashboard}
            exact
          />
          <ProtectedRoute
            path="/partner/billing"
            viewAs="partner"
            component={PartnerBillingDashboard}
            exact
          />
          <ProtectedRoute
            path="/partner/billing/payment"
            viewAs="partner"
            component={FundWallet}
            exact
          />
          <ProtectedRoute
            path="/partner/billing/history"
            component={BillingHistory}
            viewAs="partner"
            exact
          />
          <ProtectedRoute
            path="/partner/account_settings"
            component={AccountSettings}
            exact
            pageMeta={{
              title: "Account Settings",
              description: "Account Settings",
            }}
          />
          <ProtectedRoute
            enabledEnvToggle
            path="/partner/web_app"
            component={WebApp}
            exact
          />
          <ProtectedRoute
            viewAs="partner"
            enabledEnvToggle
            path="/partner/analytics"
            component={AnalyticsWrapper}
          />
          <ProtectedRoute
            path="/partner/id_status"
            viewAs="partner"
            component={IDStatus}
            exact
          />
          <ProtectedRoute
            path="/partner/id_status/:country/:id_type/:provider?"
            viewAs="partner"
            component={IDHistory}
            exact
          />

          <ProtectedRoute
            path="/reviewer/reviews/completed"
            component={CompletedReviewsContainer}
            exact
          />
          <ProtectedRoute path="/reviewer/reviews" component={Reviews} exact />
          <ProtectedRoute path="/reviewer" component={ReviewerSplash} exact />
          <ProtectedRoute
            path="/partner/support/tickets"
            component={SupportTickets}
            viewAs="partner"
            exact
          />
          <ProtectedRoute path="/404" component={NotFound} exact />
          <ProtectedRoute path="/" component={NotFound} />
        </AuthAppView>
      </Switch>
    </Sentry.ErrorBoundary>
  );
}

function AppContainer(props) {
  return (
    <HelmetProvider>
      <GlobalStyles />
      <BrowserRouter>
        <ConfigProvider theme={theme} locale={enGB}>
          <AntdApp>
            <ProConfigProvider intl={enGBIntl}>
              <div className="App">
                <App {...props} />
              </div>
            </ProConfigProvider>
          </AntdApp>
        </ConfigProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default AppContainer;

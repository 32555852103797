import React from "react";
import {
  LightIcon,
  InvertIcon,
  ExpandIcon,
  RotateLeftIcon,
  ZoomInIcon,
  RotateRightIcon,
  ZoomOutIcon,
  ResetIcon,
  FlipHorizontalIcon,
  FlipVerticalIcon,
} from "./AnimatableSVGs";

type ImageTranslationButtonProps = {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  icon: React.ReactNode;
  label: string;
};

const ImageTranslationButton: React.FC<ImageTranslationButtonProps> = ({
  onClick,
  icon,
  label,
}) => (
  <button className="image_tool_button" onClick={onClick}>
    <div className="image_tool_icon_container">
      {icon}
      <span className="visually-hidden">{label}</span>
    </div>
  </button>
);

type ImageTranslationToolsProps = {
  onZoomIn?: () => void;
  onZoomOut?: () => void;
  onReset?: () => void;
  onRotateLeft?: () => void;
  onRotateRight?: () => void;
  onFlipHorizontal?: () => void;
  onFlipVertical?: () => void;
};

const ImageTranslationTools: React.FC<ImageTranslationToolsProps> = ({
  onZoomIn,
  onZoomOut,
  onReset,
  onRotateLeft,
  onRotateRight,
  onFlipHorizontal,
  onFlipVertical,
}) => (
  <>
    {onZoomIn && (
      <ImageTranslationButton
        onClick={onZoomIn}
        icon={<ZoomInIcon cssClass="image_tool_icon" />}
        label="Zoom In"
      />
    )}
    {onZoomOut && (
      <ImageTranslationButton
        onClick={onZoomOut}
        icon={<ZoomOutIcon cssClass="image_tool_icon" />}
        label="Zoom Out"
      />
    )}
    {onReset && (
      <ImageTranslationButton
        onClick={onReset}
        icon={<ResetIcon cssClass="image_tool_icon" />}
        label="Reset"
      />
    )}
    {onRotateLeft && (
      <ImageTranslationButton
        onClick={onRotateLeft}
        icon={<RotateLeftIcon cssClass="image_tool_icon" />}
        label="Rotate Left"
      />
    )}
    {onRotateRight && (
      <ImageTranslationButton
        onClick={onRotateRight}
        icon={<RotateRightIcon cssClass="image_tool_icon" />}
        label="Rotate Right"
      />
    )}
    {onFlipHorizontal && (
      <ImageTranslationButton
        onClick={onFlipHorizontal}
        icon={<FlipHorizontalIcon cssClass="image_tool_icon" />}
        label="Flip Horizontal"
      />
    )}
    {onFlipVertical && (
      <ImageTranslationButton
        onClick={onFlipVertical}
        icon={<FlipVerticalIcon cssClass="image_tool_icon" />}
        label="Flip Vertical"
      />
    )}
  </>
);

type ImageFilterToolsProps = {
  handleFilterChange: (filter: string) => void;
  showFullScreen?: boolean;
};

const ImageFilterTools: React.FC<ImageFilterToolsProps> = ({
  handleFilterChange,
  showFullScreen = false,
}) => (
  <>
    <ImageTranslationButton
      onClick={() => handleFilterChange("invert")}
      icon={<InvertIcon cssClass="image_tool_icon" />}
      label="Invert Image"
    />
    <ImageTranslationButton
      onClick={() => handleFilterChange("brightness")}
      icon={<LightIcon cssClass="image_tool_icon" />}
      label="Change Brightness"
    />
    {showFullScreen && (
      <ImageTranslationButton
        onClick={() => handleFilterChange("expand")}
        icon={<ExpandIcon cssClass="image_tool_icon" />}
        label="Expand Image"
      />
    )}
  </>
);

export { ImageFilterTools, ImageTranslationTools };

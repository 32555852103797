import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";

interface GetPartnerIdsResponse {
  partnerIds: string[];
}

const getPartnerIds = async () => {
  const { data } = await axios.get("/companies/partner_ids");
  return { partnerIds: data.partner_ids };
};

export const PARTNERS_QUERY_KEY = "partners";

export const usePartnerIds = (
  input: { enabled: boolean },
  options?: Omit<
    UseQueryOptions<GetPartnerIdsResponse, AxiosError<{ errors: string[] }>>,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery({
    queryKey: [PARTNERS_QUERY_KEY],
    queryFn: getPartnerIds,
    enabled: input.enabled,
    ...options,
  });

import React, { useContext, useEffect, useRef, useState } from "react";
import { jwtDecode } from "jwt-decode";
import moment from "moment";
import QRCode from "react-qr-code";
import { useHistory, useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { DisplayEnvironment } from "contexts/displayEnvironment";
import { SmileLinksFormContext } from "contexts/SmileLinksFormContext";
import DownloadIcon from "resources/img/icons/download-alt.svg";
import {
  buildState,
  createSmileLink,
  fetchSmileLinksProductsConfig,
  fetchSmileLink,
} from "util/api_util";
import { css } from "util/format_helpers";
import { Toast } from "util/Toast";
import PageHeader from "../../page_header/PageHeader";
import SwitchButton from "../switch";
import AddButton from "./AddButton";
import { DEFAULT_EXPIRY_DAYS } from "./constants";
import GeneratedLink from "./GeneratedLink";
import { ImageUploadButton } from "./ImageUploadButton";
import InputLabel from "./InputLabel";
import styles from "./link_settings.module.css";
import { MediumCard } from "./MediumCard";
import ModalWrapper from "./ModalWrapper";
import PreviewLink from "./PreviewLink";
import { StyledButton } from "./StyledButton";
import tabStyles from "./tabs.module.css";
import TextInput from "./TextInput";

export default function LinkSettings() {
  const location = useLocation();
  const [tab, setTab] = useState(buildState(location.search)?.step ?? "1");
  const [logoFile, setLogoFile] = useState();
  const [showGenerated, setShowGenerated] = useState(false);
  const [errorMessage, setErrorMessage] = useState({});
  const [loading, setLoading] = useState({ refreshing: true });
  const [link, setLink] = useState("");
  const { environment } = useContext(DisplayEnvironment);
  const history = useHistory();
  const { handleChange, formFields, setFormFields } = useContext(
    SmileLinksFormContext,
  );

  const user = localStorage.token ? jwtDecode(localStorage.token) : undefined;
  useEffect(() => {
    if (link.length > 0) {
      return;
    }
    const handleBeforeUnload = (e) => {
      e.preventDefault();
      e.returnValue = "";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  });
  useEffect(() => {
    if (!user || formFields.companyName) return;
    fetchSmileLinksProductsConfig(user.partner_id)
      .then((config) => {
        const {
          callback_url: callbackUrl,
          company_name: companyName,
          data_privacy_policy_url: dataPrivacyPolicyUrl,
          redirect_url: redirectUrl,
          logo_url: logoUrl,
        } = config.product_config || {};

        const logoName = logoUrl?.split("/")?.pop();

        setFormFields((current) => ({
          previewImage: logoUrl,
          logoUrl,
          callbackUrl,
          companyName,
          dataPrivacyPolicyUrl,
          redirectUrl,
          logoName,
          ...current,
        }));
      })
      .catch(() => {});
  }, [user.partner_id, environment]);

  useEffect(() => {
    const { refId } = buildState(location.search);
    if (!refId) {
      setLoading({ refreshing: false });
      return;
    }
    setTab("2");
    setLoading({ refreshing: true });
    fetchSmileLink(refId).then((result) => {
      setLoading({ refreshing: false });
      if (result.error) {
        setErrorMessage({ linkError: result.error });
        return;
      }
      const {
        company_name: companyName,
        callback_url: callbackUrl,
        data_privacy_policy_url: dataPrivacyPolicyUrl,
        redirect_url: redirectUrl,
        id_types: idTypes,
        name,
        ref_id: refId,
        link_url,
        expires_at: expiresAt,
        is_single_use: isSingleUse,
      } = result;
      setFormFields({
        refId,
        companyName,
        callbackUrl,
        dataPrivacyPolicyUrl,
        redirectUrl,
        idTypes: idTypes.map(({ country, id_type, verification_method }) => ({
          country,
          idTypeLabel: id_type,
          idType: id_type,
          verificationMethod: verification_method,
        })),
        name,
        expiresAt,
        isEditState: true,
        isSingleUse,
      });
      handleChange("previewImage", result.logo_url);
      handleChange("logoName", result.logo_url?.split("/")?.pop());
      setLink(link_url);
    });
  }, [location.search]);

  const handleImageChange = (file) => {
    setLogoFile(file);
    setFormFields((current) => ({ ...current, previewImage: "", logoUrl: "" }));
    if (!file) {
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      handleChange("previewImage", reader.result);
    };
    reader.readAsDataURL(file);
  };

  const onPublish = () => {
    if (!formFields.name) {
      Toast.fire({ title: "Link name is required" });
      return;
    }
    setLoading({ submitting: true });
    const formData = new FormData();
    formData.append("user_id", formFields.userId || "");
    formData.append("name", formFields.name || "");
    formData.append("company_name", formFields.companyName || "");
    formData.append("redirect_url", formFields.redirectUrl || "");
    formData.append("is_single_use", formFields.isSingleUse);
    if (
      formFields.isSingleUse ||
      (formFields.expiresAt && !formFields.isNonExpiry)
    ) {
      formData.append(
        "expires_at",
        moment(formFields.expiresAt, "DD-MM-YYYY").format(),
      );
    }
    formData.append("data_privacy_policy_url", formFields.dataPrivacyPolicyUrl);
    formData.append("logo", logoFile || "");
    formFields.idTypes.forEach((id) => {
      formData.append(`id_types[][id_type]`, id.idType);
      formData.append(`id_types[][country]`, id.country_code);
      formData.append(`id_types[][verification_method]`, id.verificationMethod);
    });
    if (formFields.callbackUrl) {
      formData.append("callback_url", formFields.callbackUrl);
    }
    if (formFields.logoUrl) {
      formData.append("logo_url", formFields.previewImage);
    }
    createSmileLink(formData).then((result) => {
      setLoading({ submitting: false });
      if (!result.link) {
        setErrorMessage((current) => ({ ...current, link: result.error }));
        return;
      }
      const refId = result.link.split("/").pop();
      history.replace(`settings?refId=${refId}`);
      setLink(result.link);
      setShowGenerated(true);
      setTab("2");
      Toast.fire({ title: "Link created successfully" });
    });
  };

  const validateConfiguration = () => {
    if (!hasConfig) {
      Toast.fire({ title: "Please complete product configuration." });
      return;
    }
    setTab("2");
  };
  const hasConfig =
    formFields.dataPrivacyPolicyUrl &&
    formFields.companyName &&
    formFields.previewImage;
  const showLinkError = !loading.refreshing && errorMessage.linkError;
  const showLoading = loading.refreshing && !errorMessage.linkError;
  const showContent = !loading.refreshing && !errorMessage.linkError;
  const hasLink = formFields.isEditState || link;

  return (
    <div className={css(styles.container, "legacy new-typography")}>
      <div className={styles.manageHeader}>
        <PageHeader
          title="Smile Link"
          showBack
          onBackPressed={() => history.goBack()}
        />
      </div>
      {showLinkError && <div>{errorMessage.linkError}</div>}
      {showLoading && <LinksLoading />}
      {showContent && (
        <div className={styles.contentBody}>
          <div className={styles.settingsWrapper}>
            {!hasConfig && (
              <MediumCard
                containerClass={styles.emptyConfig}
                label="You do not have existing customisation settings for your Links. Please fill in your company details. These settings will be saved as default settings for future Smile Links."
              />
            )}
            <div className={tabStyles.tabWrapper}>
              <h4
                className={css(tabStyles.tab, tab === "1" && tabStyles.active)}
                onClick={() => setTab("1")}
              >
                App Customisation
              </h4>
              {!hasLink && (
                <h4
                  className={css(
                    tabStyles.tab,
                    tab === "2" && tabStyles.active,
                  )}
                  onClick={validateConfiguration}
                >
                  Link Settings
                </h4>
              )}
              {hasLink && (
                <h4
                  className={css(
                    tabStyles.tab,
                    tab === "2" && tabStyles.active,
                  )}
                  onClick={validateConfiguration}
                >
                  Link Details
                </h4>
              )}
            </div>
            {tab === "1" && (
              <ProductConfiguration
                linkData={formFields}
                onLogoChange={(file) => handleImageChange(file)}
                logo={logoFile}
                hasConfig={hasConfig}
                onChange={handleChange}
                onComplete={validateConfiguration}
              />
            )}
            {tab === "2" && !hasLink && (
              <CompanyLinkSettings
                onPublish={onPublish}
                linkData={formFields}
                loading={loading.submitting}
                hasConfig={hasConfig}
                onChange={handleChange}
              />
            )}
            {tab === "2" && hasLink && (
              <LinkDetails linkData={formFields} link={link} />
            )}
          </div>
          <div className={styles.previewContainer}>
            <PreviewLink
              onPublish={onPublish}
              error={errorMessage.logo}
              logoFile={formFields.previewImage}
              companyName={formFields.companyName}
            />
          </div>
        </div>
      )}
      <ModalWrapper
        isOpen={showGenerated}
        hideBackButton
        onClose={() => setShowGenerated(false)}
        title="YOUR SMILE LINK"
      >
        <div>
          <GeneratedLink link={link} />
          <StyledButton
            className="center"
            isActive
            onClick={() => {
              setShowGenerated(false);
            }}
          >
            Done
          </StyledButton>
        </div>
      </ModalWrapper>
      <ModalWrapper
        isOpen={errorMessage.link?.length > 0}
        hideBackButton
        onClick={() => setErrorMessage((current) => ({ ...current, link: "" }))}
        title="Error"
      >
        <div>
          <div style={{ marginBottom: "2rem" }}>{errorMessage.link}</div>
          <StyledButton
            className="center"
            isActive
            onClick={() =>
              setErrorMessage((current) => ({ ...current, link: "" }))
            }
          >
            Close
          </StyledButton>
        </div>
      </ModalWrapper>
      <ModalWrapper isOpen={loading.submitting} hideBackButton hideNav>
        <div className="center">Publishing...</div>
      </ModalWrapper>
    </div>
  );
}

function LinkDetails({ link, linkData }) {
  const history = useHistory();
  const { resetData } = useContext(SmileLinksFormContext);
  const svgRef = useRef(null);
  const restart = () => {
    resetData();
    history.push("new");
  };

  const idCount = linkData.idTypes.reduce(
    (reducer, value) => reducer.add(value.idType),
    new Set(),
  ).size;
  const countryCount = linkData.idTypes.reduce(
    (reducer, value) => reducer.add(value.country),
    new Set(),
  ).size;

  const expiry = (expires_at) => {
    if (!expires_at) return "Never";

    const duration = moment.duration(moment(expires_at).diff(moment()));
    const days = duration.asDays();

    if (days <= 0) return "Expired";

    const daysStr =
      days > 90
        ? `${moment(expires_at).fromNow(true)}`
        : `${days.toFixed()} days`;

    return duration.asHours() >= 0 && duration.asHours() <= 24
      ? "Today"
      : daysStr;
  };

  const downloadSVG = () => {
    const svgRoot = svgRef.current;

    if (svgRoot) {
      const svgSource = new XMLSerializer().serializeToString(svgRoot);
      const svgDataUri = `data:image/svg+xml;base64,${btoa(svgSource)}`;

      const link = document.createElement("a");
      link.setAttribute("href", svgDataUri);
      link.setAttribute("download", `${linkData.name}.svg`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const linkType = linkData.isSingleUse ? "single-use" : "multi-use";

  return (
    <div className={styles.linkDetailsWrapper}>
      <div>
        <div className={css("h4", styles.mb47)}>
          These are the details of the link you generated
        </div>
        <div className={styles.linkDetails}>
          <div className={styles.qrContainer}>
            <QRCode ref={svgRef} id="smile-link-qr" size={200} value={link} />
            <button className={styles.qrButton} onClick={downloadSVG}>
              <img src={DownloadIcon} alt="" />
            </button>
          </div>
        </div>
        <div className={styles.linkDetails}>
          <div className="h4">Link</div>
          <div className={styles.linkDetailsValue}>{link}</div>
        </div>
        <div className={styles.linkDetails}>
          <div className="h4">Link Name</div>
          <div className={styles.linkDetailsValue}>{linkData.name}</div>
        </div>
        <div className={styles.linkDetails}>
          <div className="h4">Link IDs</div>
          <div className={styles.linkDetailsValue}>
            {idCount} ID{idCount > 1 ? "s" : ""} from {countryCount}{" "}
            {countryCount > 1 ? "Countries" : "Country"}{" "}
          </div>
        </div>
        <div className={styles.linkDetails}>
          <div className="h4">Link Type</div>
          <div className={styles.linkDetailsValue}>
            {linkData.isSingleUse ? "Single-User" : "Multi-User"}
          </div>
        </div>
        <div className={styles.linkDetails}>
          <div className="h4">Expiry</div>
          <div className={styles.linkDetailsValue}>
            {expiry(linkData.expiresAt)}
          </div>
        </div>
      </div>
      <div className={styles.buttonRow}>
        <StyledButton defaultButton href={`manage?tab=${linkType}`}>
          Manage Link
        </StyledButton>
        <StyledButton isActive onClick={restart}>
          Create another link
        </StyledButton>
      </div>
    </div>
  );
}

function ProductConfiguration({
  onLogoChange,
  onChange,
  onComplete,
  linkData,
  logo,
  hasConfig,
}) {
  const [errorMessage, setErrorMessage] = useState({});
  const linkValidator = (field, name, value) => {
    onChange(field, value);
    if (field === "callbackUrl" && !value) {
      setErrorMessage((current) => ({ ...current, [field]: "" }));
      return;
    }
    try {
      const url = new URL(value);
      if (url) {
        setErrorMessage((current) => ({ ...current, [field]: "" }));
      }
    } catch (error) {
      setErrorMessage((current) => ({
        ...current,
        [field]: `${name} is not a valid url`,
      }));
    }
  };

  const validateEntry = (value) => {
    onChange("companyName", value);
    setErrorMessage((current) => ({ ...current, companyName: "" }));
    if (value.length < 2) {
      setErrorMessage((current) => ({
        ...current,
        companyName: "Company Name is too short",
      }));
    }
  };
  return (
    <div>
      <h4 className={css("sub-text-descriptor", "h4", styles.mb47)}>
        Add your company details to customise the user interface your customers
        will see.
      </h4>
      <TextInput
        label="Add Company Name"
        placeholder="eg My Awesome Company"
        value={linkData.companyName}
        disabled={linkData.isEditState}
        error={errorMessage.companyName}
        errorClassName={styles.error}
        toolTip="The company or product name you want your user to see when they view the verification screen"
        onChange={(value) => validateEntry(value)}
        wrapperClassName={styles.mb32}
      />
      <div className={styles.logoWrapper}>
        <InputLabel
          label="Add Logo"
          toolTip="The logo you want to display on the verification screen for this link."
        />
        <ImageUploadButton
          previewImage={linkData.previewImage}
          file={logo}
          fileName={linkData.logoName}
          onImageChange={onLogoChange}
          disabled={linkData.isEditState}
        />
      </div>
      <TextInput
        label="Add Data Privacy Policy URL"
        type="url"
        value={linkData.dataPrivacyPolicyUrl}
        placeholder="eg. https://app.example.com/privacy"
        onChange={(value) =>
          linkValidator("dataPrivacyPolicyUrl", "Privacy Policy", value)
        }
        toolTip="Your data or privacy policy URL will be linked on the verification screen."
        disabled={linkData.isEditState}
        error={errorMessage.dataPrivacyPolicyUrl}
        wrapperClassName={styles.mb32}
      />
      <TextInput
        label="Add Callback URL"
        type="url"
        value={linkData.callbackUrl}
        placeholder="eg. https://app.example.com/callback"
        toolTip="This webhook enables you to receive responses in your code."
        wrapperClassName={styles.mb32}
        onChange={(value) =>
          linkValidator("callbackUrl", "Callback url", value)
        }
        disabled={linkData.isEditState}
        error={errorMessage.callbackUrl}
        optional
      />
      <div className={styles.configButton}>
        <StyledButton
          isActive
          onClick={onComplete}
          disabled={linkData.isEditState || !hasConfig}
        >
          Save Configuration
        </StyledButton>
      </div>
    </div>
  );
}

function CompanyLinkSettings({
  onPublish,
  hasConfig,
  linkData,
  onChange,
  loading,
}) {
  const [errorMessage, setErrorMessage] = useState({});

  const validateInput = (field, name, value, hasMax) => {
    onChange(field, value);
    if (value.length < 3) {
      setErrorMessage((current) => ({
        ...current,
        [field]: `${name} is too short`,
      }));
      return;
    }
    if (hasMax && value.length >= 30) {
      setErrorMessage((current) => ({
        ...current,
        [field]: `${name} is too long`,
      }));
      return;
    }
    setErrorMessage((current) => ({ ...current, [field]: "" }));
  };
  const validateUserId = (value) => {
    onChange("userId", value);
    if (value.length === 0) {
      setErrorMessage((current) => ({
        ...current,
        userId: "User ID is required",
      }));
      return;
    }

    if (value.length < 3) {
      setErrorMessage((current) => ({
        ...current,
        userId: "User ID is too short",
      }));
      return;
    }
    setErrorMessage((current) => ({ ...current, userId: "" }));
  };

  const setExpiryDate = (days = DEFAULT_EXPIRY_DAYS) => {
    onChange("expiresAt", moment().add(days, "days").format("DD-MM-YYYY"));
  };

  const onSingleLinkClick = () => {
    onChange("isSingleUse", true);
    onChange("isNonExpiry", false);
  };

  const noOfDays =
    moment(linkData.expiresAt, "DD-MM-YYYY").diff(moment(), "days") + 1;

  const isSingleLink = linkData.isSingleUse;
  const hasError = errorMessage.name || (isSingleLink && errorMessage.userId);
  return (
    <div>
      <div className={css("h4", styles.mb47)}>
        These details will enable you track and manage your Links
      </div>
      <TextInput
        label="Give your link a name"
        placeholder="eg. Africa Onboarding"
        toolTip="This is the name you will use to track your Smile Link within the Smile Portal."
        wrapperClassName={styles.mb32}
        value={linkData?.name}
        error={errorMessage.name}
        onChange={(value) => validateInput("name", "Link name", value, true)}
      />
      <InputLabel
        label="Are you creating this link for a single user or multiple users?"
        toolTip="A Single-User Link is for one-time use by a single user only. A Multi-User Link is generated once but anyone with it may use it as many times as needed until it expires."
        wrapperClassName={styles.mb10}
      />
      <div className={css(styles.singleLinkToggle)}>
        <StyledButton
          className={css(styles.btn, isSingleLink ? styles.softBorder : "")}
          isActive={!isSingleLink}
          onClick={() => onChange("isSingleUse", false)}
        >
          Multi-User Link
        </StyledButton>
        <StyledButton
          className={css(styles.btn, !isSingleLink ? styles.softBorder : "")}
          isActive={isSingleLink}
          onClick={() => onSingleLinkClick()}
        >
          Single-User Link
        </StyledButton>
      </div>
      {isSingleLink && (
        <SingleUseLink
          validateInput={validateUserId}
          setErrorMessage={setErrorMessage}
          onChange={onChange}
          error={errorMessage.userId}
          userId={linkData.userId}
        />
      )}
      <div className={styles.expiryContainerInput}>
        <div className="flex" style={{ marginBottom: "1rem" }}>
          <InputLabel
            label="Enter link expiry in days"
            toolTip="Your link no longer be active after the specified number of days. Change the expiry period or set to never expire."
            className={styles.switch_label_text}
          />
        </div>
        {!linkData.isNonExpiry && (
          <TextInput
            hideIcon
            value={noOfDays}
            type="number"
            onChange={(val) => setExpiryDate(val)}
          />
        )}
        {linkData.isNonExpiry && (
          <TextInput hideIcon value="Never expires" type="text" disabled />
        )}
        {!linkData.isSingleUse && (
          <div className={styles.nonExpiry}>
            <SwitchButton
              id="non-expiry"
              isOn={linkData.isNonExpiry}
              size={40}
              onChange={() => onChange("isNonExpiry", !linkData.isNonExpiry)}
            />{" "}
            Keep this link active forever
          </div>
        )}
      </div>
      <div className={styles.publishButton}>
        <StyledButton
          onClick={onPublish}
          isActive
          disabled={!hasConfig || loading || hasError || !linkData?.name}
        >
          {loading ? "Publishing link ..." : "Publish Link"}
        </StyledButton>
      </div>
    </div>
  );
}

function SingleUseLink({
  userId,
  error,
  validateInput,
  onChange,
  setErrorMessage,
}) {
  const generateUserId = () => {
    onChange("userId", uuidv4());
    setErrorMessage((current) => ({ ...current, userId: "" }));
  };

  return (
    <div className="legacy">
      <TextInput
        label="Enter a User ID"
        value={userId}
        error={error}
        toolTip="A User ID is a unique identifier you assign to track your users in Smile ID's Portal."
        onChange={(value) => validateInput(value)}
      />
      <AddButton
        label="Auto-Generate User ID"
        icon
        onClick={generateUserId}
        wrapperClassName={styles.contentRight}
        style={{ border: "none", marginBottom: "2rem", marginRight: "1.3rem" }}
      />
    </div>
  );
}

function LinksLoading() {
  return (
    <div className="flex shimmer-wrapper legacy">
      <div>
        <div className="flex" style={{ marginBottom: "2rem" }}>
          <div
            className="animate-shimmer shimmer-text"
            style={{ width: "10rem", height: "1rem", marginRight: "1rem" }}
          />
          <div
            className="animate-shimmer shimmer-text"
            style={{ width: "10rem", height: "1rem" }}
          />
        </div>
        <div
          className="animate-shimmer shimmer-text"
          style={{ width: "100%", height: "40vh", marginBottom: "2rem" }}
        />
        <div
          className="animate-shimmer shimmer-text align-end"
          style={{ width: "10rem", height: "2rem" }}
        />
      </div>
      <div
        className="animate-shimmer shimmer-text"
        style={{ width: "40%", height: "50vh" }}
      />
    </div>
  );
}
